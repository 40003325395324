.analyzer-container {
  display: flex;
  width: 95%;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding-bottom: 20px;
    

  h2 {
    color: #000;
    /* M3/headline/medium */
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 128.571% */
  }

}

.instruction-card {
  border-radius: 12px;
  display: flex;
  height: 126px;
  align-items: flex-start;
  align-self: stretch;

  Card {
    background: #000;
  }
}

.upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;

}

.icon {
  fill: white;
  transition: fill 0.2s ease-in-out;
}

.icon:hover {
  fill: white;
}