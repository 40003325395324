.posts-container {
  display: flex;
  width: 95%;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding-top: 20px;
    

  h2 {
    color: #000;
    /* M3/headline/medium */
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 128.571% */
  }

  p {
    color: var(--m-3-sys-light-on-surface, #1D1B20);

    /* M3/body/large */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
  }
}
